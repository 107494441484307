/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { object, string } from 'prop-types';

const styles = () => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        background: '#f4f0f6',
        padding: '5px',
        '& img': {
            display: 'block',
            margin: '0 auto',
            padding: '5px',
        },
    },
});

const GraphqlDesktopBanner = ({ classes, bannerImage }) => {
    if (bannerImage) {
        return (
            <Grid container className={classes.container}>
                <img alt={bannerImage === 'undefined' ? '' : '1800Flowers promos and coupons'} src={`https://cdn1.1800flowers.com/wcsstore/Flowers/images/banners/${bannerImage}?width=1094`} />
            </Grid>
        );
    }
    return null;
};

GraphqlDesktopBanner.propTypes = {
    classes: object.isRequired,
    bannerImage: string.isRequired,
};

export default withStyles(styles)(GraphqlDesktopBanner);
