/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import { object, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import Cookies  from 'universal-cookie';

import { GRAPHQL_ENV } from '../../../gql';

import ConnectedGraphqlBannerCodeBuilder from './GraphqlBannerCodeBuilder';

const GraphqlBannerCodeContainer = ({ brand, bannerCode }) => {
    if (!bannerCode) {
        return null;
    }

    const BANNER_PAGE_QUERY = gql`
        query BannerPageQuery {
            findBannerCode(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "en-us", code: "${bannerCode}") {
                content
        }
    }
`;

    return (
        <Query query={BANNER_PAGE_QUERY}>
            {({ loading, data, error }) => {
                if (loading) {
                    return null;
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: BANNER_PAGE_QUERY,
                        component: 'GraphqlBannerCodeContainer.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: BANNER_PAGE_QUERY,
                        component: 'GraphqlBannerCodeContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (!data.findBannerCode?.content) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: BANNER_PAGE_QUERY,
                        component: 'GraphqlBannerCodeContainer.js',
                        message: 'No content returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                const bannerCodeArr = { ...{ ...{ ...data }.findBannerCode }.content }.entries;
                const promoCode = bannerCodeArr?.[0]?.promo_code;
                const bannerEyeBrow = bannerCodeArr?.[0]?.image_name;
                const defaultPaymentMethod = bannerCodeArr?.[0]?.default_payment_method || 'CreditCard';

                if (promoCode) {
                    const cookies = new Cookies();
                    const bannerCookie = cookies.get('banner');

                    console.log({
                        message: 'Debug: Banner Code',
                        errorMessage: {
                            ...bannerCookie,
                        },
                    });

                    cookies.set('banner', {
                        ...bannerCookie,
                        c: bannerCookie?.c || bannerCode || '',
                        e: bannerEyeBrow,
                        p: promoCode,
                        mp: defaultPaymentMethod,
                    }, {
                        path: '/',
                        encode: String,
                    });
                }
                return (
                    <Grid>
                        {
                            bannerCodeArr.length !== 0 && <ConnectedGraphqlBannerCodeBuilder brand={brand} pageData={data} />
                        }
                    </Grid>
                );
            }}
        </Query>
    );
};

GraphqlBannerCodeContainer.propTypes = {
    brand: object.isRequired,
    bannerCode: string,
};

GraphqlBannerCodeContainer.defaultProps = {
    bannerCode: '',
};

export default withRouter((GraphqlBannerCodeContainer));
