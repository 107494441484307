/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { object } from 'prop-types';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import * as app from '../../../../state/ducks/App';
import GraphqlDesktopBanner from './Partials/GraphqlDesktopBanner/GraphqlDesktopBanner';
import GraphqlMobileBanner from './Partials/GraphqlMobileBanner/GraphqlMobileBanner';

const brandSelectors = app.ducks.brand.selectors;

const GraphqlBannerCodeBuilder = ({ pageData }) => {
    const bannerData = pageData.findBannerCode.content.entries[0];
    const { image_name = '', mobile_image_name = '' } = bannerData;

    return (
        <>
            <DesktopMobile
                desktop={() => <GraphqlDesktopBanner bannerImage={image_name} />}
                mobile={() => <GraphqlMobileBanner bannerImage={mobile_image_name} />}
            />
        </>
    );
};

GraphqlBannerCodeBuilder.propTypes = {
    pageData: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});

export default withRouter(connect(mapStateToProps)(GraphqlBannerCodeBuilder));
